body {
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.8)), to(hsla(0, 0%, 100%, 0.8))), url('../images/black-orchid.png');
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0.8)), url('/20150810114905/assets/images/black-orchid.png');
	background-position: 0px 0px, 0px 0px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

h1 {
	width: 100%;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-bottom: 0.15rem;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	color: #726869;
	font-size: 2.25rem;
	line-height: 2.75rem;
	font-weight: 400;
	letter-spacing: 0.01em;
}

h2 {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #726869;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 400;
	letter-spacing: 0.01em;
}

h3 {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #726869;
	font-size: 1.4125em;
	line-height: 1.4125em;
	font-weight: 400;
	letter-spacing: 0.01em;
}

h4 {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #726869;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
	letter-spacing: 0.01em;
}

h5 {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #726869;
	font-size: 0.9rem;
	line-height: 1.35rem;
	font-weight: 500;
	letter-spacing: 0.01em;
}

h6 {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #726869;
	font-size: 0.825rem;
	line-height: 1.15rem;
	font-weight: 500;
	letter-spacing: 0.01em;
	text-transform: uppercase;
}

p {
	margin-top: 0.375rem;
	margin-bottom: 0.75rem;
}

a {
	border-bottom: 1px solid #ddd;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	font-family: Montserrat, sans-serif;
	color: #96653d;
	text-decoration: none;
}

a:hover {
	border-bottom-color: transparent;
	color: #25282a;
	text-decoration: none;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1em 1.25em 1.25em;
	border-left: 0px none transparent;
	background-color: #000;
	box-shadow: -4px 0 0 0 #e5c754;
	font-family: Oswald, sans-serif;
	color: #fff;
	font-size: 1.1875em;
	line-height: 1.5em;
	font-weight: 400;
}

.button {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.5em 1.3em;
	border-bottom-style: none;
	background-color: #96653d;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	background-color: #664428;
	color: #fff;
}

.button.secondary {
	background-color: #373a3d;
	color: #fff;
	font-weight: 400;
}

.button.secondary:hover {
	background-color: #000;
	color: #fff;
}

.button.right-sidebar-search-button {
	width: 25%;
	height: 2.5rem;
	min-width: 2.5rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-right: 0em;
	padding-left: 0em;
	float: left;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-image: url('/20150810114905/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: hsla(0, 0%, 100%, 0);
}

.button.header-search-button {
	position: relative;
	width: 3rem;
	height: 3rem;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 0rem 1.25rem;
	border-radius: 1.75rem;
	background-color: #939598;
	background-image: url('/20150810114905/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: transparent;
	font-size: 1rem;
	line-height: 1rem;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: #96653d;
}

.button.add-to-my-links-button {
	position: relative;
	top: 0px;
	right: 0px;
	display: none;
	margin: 0.5rem 0px;
	padding: 0.25em 1em 0.3125em 1.75rem;
	background-color: #373a3d;
	background-image: url('/20150810114905/assets/images/mark-as-favorite-star.svg');
	background-position: 8px 44%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.6875rem;
	font-weight: 400;
	letter-spacing: 0.08em;
}

.button.add-to-my-links-button:hover {
	background-color: #000;
	color: #ddd;
}

.container {
	height: 100%;
	max-width: 1280px;
	min-height: 100%;
	padding-right: 20px;
	padding-left: 20px;
}

.container.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.container.footer.gdrp {
	position: relative;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.container.map {
	padding-right: 0px;
	padding-left: 0px;
}

.nav-section {
	background-color: hsla(0, 0%, 100%, 0.9);
}

.nav-container {
	position: relative;
	max-width: 100%;
}

.nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1px;
	padding: 1rem 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-bottom-style: none;
	box-shadow: 0 -3px 0 0 transparent, 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: #000;
	box-shadow: 0 -3px 0 0 #96653d, 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
}

.nav-link.w--current {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -3px 0 0 #96653d, 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
}

.nav-link.w--current:hover {
	background-color: #000;
	box-shadow: 0 -3px 0 0 #96653d, 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px none hsla(0, 0%, 100%, 0.25);
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 5px 20px;
	float: left;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-bottom-style: none;
	-webkit-transition-duration: 1000ms;
	transition-duration: 1000ms;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	font-family: Oswald, sans-serif;
	color: #000;
	font-size: 4rem;
	line-height: 1em;
	font-weight: 500;
	letter-spacing: -0.04em;
	z-index: 2000;
}

.logo:hover {
	color: #c9a51d;
}

.logo.w--current {
	margin-top: 5px;
	margin-bottom: 5px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.footer-section {
	position: relative;
	background-color: #fff;
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logo-text {
	padding-bottom: 5px;
	float: left;
}

.footer-signoff-section {
	padding-top: 2rem;
	padding-bottom: 4rem;
	background-color: #373a3d;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(55, 58, 61, 0.5)), to(rgba(55, 58, 61, 0.5))), url('/20150810114905/assets/images/black-orchid.png');
	background-image: linear-gradient(180deg, rgba(55, 58, 61, 0.5), rgba(55, 58, 61, 0.5)), url('/20150810114905/assets/images/black-orchid.png');
	background-position: 0px 0px, 50% 50%;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.75rem;
	margin-left: 0rem;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	padding-left: 32px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-image: url('/20150810114905/assets/images/globalreach_knockout_fullwhite.png');
	background-size: 130px;
	background-repeat: no-repeat;
	opacity: 0.6;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	opacity: 1;
	color: #fff;
}

.footer-column-wrap {
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	clear: both;
}

.footer-column-title {
	margin-top: 1rem;
	font-size: 1.375em;
	line-height: 1.125em;
	font-weight: 500;
	text-transform: uppercase;
}

.header-content-right-wrap {
	position: absolute;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: 2rem;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-contents-wrap {
	position: relative;
	border-top: 3px none #94653e;
	background-color: transparent;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0.5rem 1rem 0.5rem 0.125rem;
	padding-left: 0px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	font-family: Oswald, sans-serif;
	color: #e5c754;
	font-size: 0.6875rem;
	line-height: 1em;
	font-weight: 500;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	background-color: rgba(0, 0, 0, 0.75);
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field-label {
	font-family: Poppins, sans-serif;
}

.hidden-form-field {
	position: relative;
	display: block;
	width: 275px;
	height: 3rem;
	margin-right: -1.5rem;
	margin-bottom: 0rem;
	padding: 4px 10px 4px 1.25rem;
	border-top-left-radius: 1.5rem;
	border-bottom-left-radius: 1.5rem;
	background-color: #fff;
	opacity: 1;
	font-family: Poppins, sans-serif;
	font-size: 0.75rem;
}

.hidden-form-field::-webkit-input-placeholder {
	color: #25282a;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.hidden-form-field:-ms-input-placeholder {
	color: #25282a;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.hidden-form-field::-ms-input-placeholder {
	color: #25282a;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.hidden-form-field::placeholder {
	color: #25282a;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.hidden-form-field.right-sidebar-search-field {
	width: 75%;
	height: 2.5rem;
	margin-bottom: 0em;
	padding-right: 8px;
	padding-left: 8px;
	float: left;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-radius: 0px;
}

.hidden-form-field.header-search-field {
	display: none;
	width: 0px;
	height: 2.75rem;
	margin-bottom: 0em;
	padding-right: 1em;
	padding-left: 1em;
	border: 1px solid #ddd;
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
}

.hidden-form-field.header-search-field:focus {
	width: 220px;
	background-color: #fff;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #96653d;
	font-family: Poppins, sans-serif;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c30a0f;
	color: #fff;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
	color: #96653d;
}

.left-nav-column {
	padding-right: 20px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: 0 1px 0 0 #ddd;
}

.left-nav-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #ddd;
}

.left-nav-list-link {
	display: block;
	padding: 0.875em 1em 0.75em 1.125em;
	border-bottom-style: none;
	background-image: url('/20150810114905/assets/images/brownArrow-01.svg');
	background-position: 2px 20px;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	color: rgba(0, 0, 0, 0.65);
	line-height: 1.41255em;
	font-weight: 400;
}

.left-nav-list-link:hover {
	padding-right: 0.75em;
	padding-left: 1.375em;
	background-image: url('/20150810114905/assets/images/brownArrow-01.svg');
	color: #000;
}

.left-nav-list-link.w--current {
	background-image: url('/20150810114905/assets/images/brownArrow-01.svg');
	color: #000;
}

.left-nav-list-link.w--current:hover {
	background-image: url('/20150810114905/assets/images/brownArrow-01.svg');
	background-size: auto 9px;
	background-repeat: no-repeat;
	color: #96653d;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em 0em 0em 0.75em;
	border-top: 2px solid #96653d;
	background-color: transparent;
	background-image: none;
	background-position: 0px 0px;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.4375em 0.75em 1.125em;
	border-bottom-style: none;
	background-image: url('/20150810114905/assets/images/brownArrow-01.svg');
	background-position: 4px 20px;
	background-size: 6px 6px;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	color: rgba(0, 0, 0, 0.65);
	font-size: 0.9375em;
	line-height: 1.375em;
	font-weight: 400;
}

.left-nav-nested-list-link:hover {
	padding-right: 1.25em;
	padding-left: 1.25em;
	color: #000;
}

.left-nav-nested-list-link.w--current {
	background-image: url('/20150810114905/assets/images/brownArrow-01.svg');
	color: #000;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 5px;
	padding-left: 5px;
	border-top: 2px solid #96653d;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-section-wrap.reduce-space {
	margin-bottom: 1.25em;
}

.sidebar-section-title {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	font-family: Poppins, sans-serif;
	font-size: 1.375em;
}

.right-sidebar-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.sidebar-list {
	padding-left: 0px;
}

.sidebar-list.archive-list {
	-webkit-column-count: 3;
	column-count: 3;
	-webkit-column-gap: 1em;
	column-gap: 1em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	padding-top: 594px;
	overflow: hidden;
}

.section-wrap.inside-content-section.insidepages {
	padding-top: 0px;
}

.secondary-nav-icon {
	width: 1rem;
	height: 1.25rem;
	margin-right: 0.5rem;
	float: left;
}

.secondary-nav-text {
	float: left;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 10px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: #000;
	font-size: 0.75rem;
	font-weight: 500;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	background-color: #e5c754;
}

.inside-page-header-content-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1.5em;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.inside-page-header-section {
	position: relative;
	height: 250px;
	margin-top: -50px;
	margin-right: -10px;
	margin-left: -10px;
	background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(0, 0, 0, 0.25)), -webkit-gradient(linear, left top, left bottom, from(rgba(55, 58, 61, 0.8)), to(rgba(55, 58, 61, 0.8))), url('/20150810114905/assets/images/black-orchid.png');
	background-image: radial-gradient(circle farthest-corner at 50% 50%, transparent, rgba(0, 0, 0, 0.25)), linear-gradient(180deg, rgba(55, 58, 61, 0.8), rgba(55, 58, 61, 0.8)), url('/20150810114905/assets/images/black-orchid.png');
	background-position: 0px 0px, 0px 0px, 50% 50%;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1360px;
	margin: -130px auto 4rem;
	padding-top: 4rem;
	padding-bottom: 4rem;
	border: 3px none #94653e;
	background-color: #fff;
	box-shadow: none;
}

.inside-content-beckground.map-section {
	margin-top: 4rem;
	padding-top: 3.5rem;
	border-style: none;
}

.inside-content-beckground.insidepages {
	margin-top: 40px;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1360px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 10px;
	padding-left: 10px;
}

.intro-text-style {
	font-family: Poppins, sans-serif;
	font-size: 1.3rem;
	line-height: 1.85rem;
}

.footer-links-list-item {
	margin-bottom: 1px;
}

.footer-list-link {
	display: block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.right-sidebar-list-icon {
	display: inline-block;
	width: 1.625em;
	height: 1.625em;
	margin-left: -30px;
	padding: 4px;
	float: left;
	border-radius: 4px;
	background-color: #25282a;
}

.right-sidebar-list-icon.rss-icon {
	padding: 0px 0px 2px 2px;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
}

.footer-link {
	margin-bottom: 0.15rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	font-family: Poppins, sans-serif;
}

.footer-link.footer-signoff-link {
	border-bottom-color: hsla(0, 0%, 100%, 0.1);
	color: hsla(0, 0%, 100%, 0.65);
}

.footer-link.footer-signoff-link:hover {
	border-bottom-color: transparent;
	color: #fff;
}

.link {
	font-family: Poppins, sans-serif;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.post-pagination-link {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.link.post-pagination-link.next-page {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.link.h3-link {
	font-family: Poppins, sans-serif;
	color: #96653d;
}

.link.h3-link:hover {
	color: #000;
}

.link.h2-link {
	font-family: Poppins, sans-serif;
	color: #96653d;
	line-height: 1.375em;
}

.link.h2-link:hover {
	color: #000;
}

.link.breadcrumb-link {
	border-bottom-color: transparent;
	font-family: Poppins, sans-serif;
	color: rgba(0, 0, 0, 0.65);
	font-weight: 400;
}

.link.breadcrumb-link:hover {
	border-bottom-color: #d02210;
	color: #000;
}

.link.h4-link {
	font-family: Poppins, sans-serif;
	color: #96653d;
}

.link.h5-link {
	font-family: Poppins, sans-serif;
}

.link.h6-link {
	font-family: Poppins, sans-serif;
	color: #96653d;
}

.link.h6-link:hover {
	color: #000;
}

.link.blockquote-link {
	border-bottom-color: hsla(0, 0%, 100%, 0.35);
}

.link.blockquote-link:hover {
	border-bottom-color: #e3151a;
	color: hsla(0, 0%, 100%, 0.7);
}

.link.h1-link {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 400;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #ddd;
}

.table-style-embed {
	font-family: Poppins, sans-serif;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-list-link-wrap {
	display: block;
	padding-left: 30px;
	border-bottom-style: none;
}

.right-sidebar-list-link {
	display: inline;
}

.post-item-wrap {
	padding-top: 1.5em;
	padding-bottom: 1.5em;
	border-top: 1px solid #ddd;
}

.post-item-wrap.first-post {
	padding-top: 0em;
	border-top: 0px none transparent;
}

.post-item-title {
	margin-top: 0em;
}

.post-item-featured-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	max-height: 400px;
	margin-top: 1em;
	margin-bottom: 1em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
}

.post-item-featured-image {
	width: 100%;
}

.post-item-read-more-wrap {
	margin-top: 1em;
}

.post-item-smaller-text {
	font-size: 0.875em;
	line-height: 1.45em;
}

.post-pagination-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.post-pagination-text {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px solid hsla(0, 0%, 100%, 0.25);
}

.nav-drop-wrap.last-nav-drop-wrap {
	border-right-style: none;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: hsla(0, 0%, 65.5%, 0.5);
}

.nav-drop-toggle:hover {
	background-color: #000;
	box-shadow: 0 -3px 0 0 #96653d;
	color: #fff;
}

.nav-drop-toggle.w--open {
	background-color: #000;
	box-shadow: 0 -3px 0 0 #96653d;
	color: #fff;
}

.nav-drop-toggle.w--open:hover {
	box-shadow: 0 -3px 0 0 #96653d;
	color: #e5c754;
}

.nav-drop-icon {
	display: none;
	margin-right: 1.5rem;
}

.nav-drop-list {
	display: none;
	overflow: hidden;
}

.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: rgba(0, 0, 0, 0.85);
	color: #fff;
}

.nav-drop-list-link {
	padding: 0.5em 1.5em 0.5em 0.5rem;
	border-bottom-style: none;
	color: #fff;
	font-size: 0.875rem;
}

.nav-drop-list-link:hover {
	color: #d3d3d3;
}

.nav-drop-list-link.w--current {
	color: #e5c754;
}

.nav-drop-toggle-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 1rem 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.nav-drop-toggle-link:hover {
	color: #fff;
}

.nav-drop-toggle-link.w--current {
	background-color: #c2c2c2;
	box-shadow: 0 -3px 0 0 #726869;
	color: #000;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1280px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-search-wrap {
	margin-bottom: 0px;
	margin-left: 1.5rem;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #373a3d;
}

.logo-subtext {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 2px solid #e5c754;
	opacity: 0.65;
	color: #000;
	font-size: 0.75rem;
	line-height: 1.125em;
	letter-spacing: -0.01em;
	text-transform: uppercase;
}

.footer-logos-section-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border-left: 1px none #ddd;
}

.footer-logo-link-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	margin: 15px;
	padding: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	box-shadow: none;
}

.footer-logo-link-wrap:hover {
	box-shadow: none;
	opacity: 0.75;
}

.footer-logo-image.sf-logo-image {
	margin-top: 5px;
}

.footer-logo-image.gator-logo-image {
	margin-bottom: 5px;
}

.footer-logo-image.greentech-logo-image {
	margin-bottom: 10px;
}

.header-logos-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 10px 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-logo-link {
	overflow: hidden;
	max-width: 120px;
	margin-right: 2%;
	margin-left: 2%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
}

.header-logo-link:hover {
	opacity: 0.75;
}

.header-logo-image {
	width: auto;
	max-height: 80px;
}

.header-logo-image.green-tech-icon {
	max-height: 76px;
}

.header-logo-image.gator-icon {
	max-height: 76px;
}

.logo-text-span {
	color: #000;
}

.intro-text-style {
	font-family: Poppins, sans-serif;
}

.footer-logo-column {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 25%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border-right: 1px none #ddd;
}

.image-placeholder {
	width: 100%;
}

.rotator {
	height: 850px;
	max-height: 850px;
	background-color: #e2e2e2;
}

.slidenav {
	width: 90%;
	opacity: 1;
	font-size: 0.75rem;
}

.slideshow-arrow {
	position: absolute;
	left: 0px;
	bottom: 0px;
	display: block;
	width: 70px;
	height: 70px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	opacity: 0.6;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	color: #fff;
}

.slideshow-arrow:hover {
	opacity: 1;
	color: #1ba5a5;
}

.slideshow-arrow.slide-arrow-right {
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	width: 50px;
	height: 50px;
	margin-top: 525px;
	margin-right: 2rem;
	border-radius: 3rem;
	background-color: #939598;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	font-size: 1.25rem;
}

.slideshow-arrow.slide-arrow-right:hover {
	color: #fff;
}

.slideshow-arrow.slide-arrow-left {
	left: 0%;
	top: 0%;
	right: auto;
	bottom: 0%;
	width: 50px;
	height: 50px;
	margin-top: 525px;
	margin-left: 2rem;
	border-radius: 3rem;
	background-color: #939598;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	font-size: 1.25rem;
}

.slideshow-arrow.slide-arrow-left:hover {
	color: #fff;
}

.button-2 {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 8px 16px 9px;
	border: 2px solid #00283b;
	background-color: #00283b;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button-2:hover {
	border-color: #e77c25;
	background-color: #fff;
	color: #00283b;
}

.button-2.slide-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 175px;
	height: auto;
	margin-top: 1rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 0px 0.3rem;
	float: right;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-style: solid;
	border-color: #fff;
	background-color: rgba(97, 55, 20, 0.8);
	font-family: Poppins, sans-serif;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}

.button-2.slide-button:hover {
	border-color: #fff;
	background-color: rgba(0, 0, 0, 0.65);
	color: #fff;
}

.slide-caption-header-wrap {
	min-width: 500px;
	border: 3px solid #fff;
	box-shadow: none;
}

.slide-image-wrap {
	width: 100%;
	height: 100%;
	background-color: #fff;
}

.slide-image-wrap.slide-image-three {
	background-image: url('/20150810114905/assets/images/healthcare_1903x1000.png');
}

.slide-image-wrap.slide-image-two {
	background-image: url('/20150810114905/assets/images/handshake_1903x1000.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	color: #000;
}

.slide-image-wrap.slide-image-one {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-image-wrap.slide-image-four {
	background-image: url('/20150810114905/assets/images/business-buildings_1903x1000.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-caption-emphasis {
	font-family: Poppins, sans-serif;
	color: #fff;
	font-weight: 600;
}

.rotator-section {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 1;
}

.slide-caption-header {
	display: block;
	max-width: 650px;
	margin: 5px;
	padding: 1rem 1.5rem;
	background-color: rgba(0, 0, 0, 0.75);
	box-shadow: none;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 2.5rem;
	line-height: 2.75rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.07rem;
}

.slide {
	background-color: #fff;
}

.slide-caption-wrapper {
	position: relative;
	left: 0px;
	right: 0px;
	bottom: 35.5%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	max-width: 90%;
	min-width: 350px;
	margin-right: auto;
	margin-left: auto;
	padding: 1.5rem 2rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.slide-caption-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image {
	width: auto;
	height: 200px;
	padding-top: 0px;
	padding-bottom: 0px;
	background-color: transparent;
	box-shadow: none;
}

.search-icon-button {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.5em 1.3em;
	border-bottom-style: none;
	background-color: #e5c754;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Oswald, sans-serif;
	color: #000;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
}

.search-icon-button:hover {
	background-color: #000;
	color: #fff;
}

.search-icon-button.header-search-button {
	position: relative;
	width: 3rem;
	height: 3rem;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 0rem 1.25rem;
	border-radius: 1.75rem;
	background-color: #939598;
	background-image: url('/20150810114905/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: transparent;
	font-size: 1rem;
	line-height: 1rem;
	text-transform: uppercase;
}

.search-icon-button.header-search-button:hover {
	background-color: #96653d;
}

.case-and-news-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.case-notes-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	display: block;
}

.case-notes-title {
	margin-bottom: 1rem;
	padding-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	font-size: 3rem;
	line-height: 3.25rem;
}

.notes-detail-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-right: 1px solid #d3d3d3;
}

.note-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-height: 200px;
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px solid #96653d;
	background-color: hsla(0, 0%, 82.7%, 0.35);
}

.case-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: auto;
	min-width: 130px;
	padding: 1rem 1rem 0.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	font-family: Poppins, sans-serif;
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-weight: 500;
}

.description {
	padding-right: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	font-family: Poppins, sans-serif;
}

.view-more-link {
	margin-top: 1rem;
	padding: 0.5rem 1rem;
	border-style: solid;
	border-width: 1px;
	border-color: #d3d3d3;
	font-family: Poppins, sans-serif;
}

.view-more-link:hover {
	background-color: #25282a;
	color: #d3d3d3;
}

.news-detail-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-right: 1px none #d3d3d3;
}

.news-title {
	margin-bottom: 1rem;
	padding-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	font-size: 3rem;
	line-height: 3.25rem;
}

.footer-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.footer-column.middle {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-column.linkedin {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.finley-law-firm-title {
	margin-bottom: 1rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
}

.address {
	padding-bottom: 0.5rem;
	font-family: Poppins, sans-serif;
}

.phone {
	padding-bottom: 0.5rem;
	font-family: Poppins, sans-serif;
}

.quicklinks-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.link-block {
	width: 50px;
	height: 50px;
	border-radius: 3rem;
	background-color: #726869;
	background-image: url('/20150810114905/assets/images/linkedin_white.svg');
	background-position: 50% 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
}

.link-block:hover {
	background-color: #96653d;
}

.map-image-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.html-embed {
	width: 100%;
}

.gdrp-section {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: block;
	min-height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #d3d3d3;
	background-color: #000;
	box-shadow: 0 -13px 0 0 rgba(179, 182, 183, 0.75);
}

.cookie-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	min-width: 425px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cookie-text-para {
	width: 66.66%;
	padding-right: 1rem;
	padding-left: 1rem;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.3rem;
}

.gdrp-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	font-family: Poppins, sans-serif;
	color: #d3d3d3;
	text-align: center;
}

.gdrp-link:hover {
	color: #fff;
}

.exit-icon-div {
	position: absolute;
	left: auto;
	top: 1rem;
	right: 1rem;
	bottom: auto;
	width: 30px;
	height: 30px;
	border-bottom-style: none;
	background-image: url('/20150810114905/assets/images/close-icon-lt-grey.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.65;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	text-decoration: none;
}

.exit-icon-div:hover {
	opacity: 1;
}

.open-gdrp-icon {
	width: 50px;
	height: 50px;
	margin-right: 4rem;
	padding-right: 0px;
	border-bottom-style: none;
	background-image: url('/20150810114905/assets/images/gear-icon-brown.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	text-decoration: none;
}

.open-gdrp-icon:hover {
	background-image: url('/20150810114905/assets/images/gear-icon-greyish-brown.svg');
	background-size: contain;
}

.gdrp-section-icon {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: block;
	min-height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #d3d3d3;
	background-color: #fff;
	box-shadow: 0 -13px 0 0 rgba(179, 182, 183, 0.75);
}

.gdrp-section-icon.just-icon {
	z-index: 100000000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: transparent;
	box-shadow: none;
}

.cookie-button {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 8px 16px 9px;
	border: 2px solid #00283b;
	background-color: #00283b;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.cookie-button:hover {
	border-color: #e77c25;
	background-color: #fff;
	color: #00283b;
}

.cookie-button.cookies {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 12px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: solid;
	border-color: #373a3d;
	background-color: #373a3d;
	opacity: 1;
	font-family: Poppins, sans-serif;
}

.cookie-button.cookies:hover {
	border-color: #726869;
	background-color: #000;
	color: #fff;
}

.top-case-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.top-firm-updates-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.quicklinks-into-columns {
	display: -ms-grid;
	display: grid;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 8px;
	grid-template-areas: ". ." ". ." ". ." ". .";
	-ms-grid-columns: 1fr 16px 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto 8px auto 8px auto 8px auto;
	grid-template-rows: auto auto auto auto;
}

.firm-update-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-height: 200px;
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px solid #96653d;
	background-color: rgba(156, 167, 175, 0.3);
}

.case-date {
	margin-bottom: 0.5rem;
	margin-left: 1rem;
	padding-top: 0.2rem;
	padding-left: 1.5rem;
	background-image: url('/20150810114905/assetsimages/calendar-icon_brown-2.svg');
	background-position: 0% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	line-height: 1.5rem;
	font-weight: 600;
}

.more-update-link {
	border-bottom-color: #adadad;
	color: #805634;
}

.body {
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.8)), to(hsla(0, 0%, 100%, 0.8))), url('../images/black-orchid.png');
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0.8)), url('/20150810114905/assets/images/black-orchid.png');
	background-position: 0px 0px, 0px 0px;
}

.div-block-2 {
	border-top: 3px none #94653e;
}

.heading {
	font-family: Poppins, sans-serif;
	font-weight: 400;
}

.heading-2 {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 400;
}

.heading-3 {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 400;
}

.paragraph {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.heading-4 {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 600;
}

.paragraph-2 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.paragraph-3 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.heading-5 {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 600;
}

.paragraph-4 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.heading-6 {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 600;
}

.paragraph-5 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.text-block {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.text-block-2 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.paragraph-6 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.heading-7 {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 600;
}

.paragraph-7 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.heading-8 {
	font-family: Poppins, sans-serif;
}

.paragraph-8 {
	font-family: Poppins, sans-serif;
}

.heading-9 {
	font-family: Poppins, sans-serif;
}

.paragraph-9 {
	font-family: Poppins, sans-serif;
}

.paragraph-10 {
	font-family: Poppins, sans-serif;
}

.heading-10 {
	font-family: Poppins, sans-serif;
}

.paragraph-11 {
	font-family: Poppins, sans-serif;
}

.heading-11 {
	font-family: Poppins, sans-serif;
}

.paragraph-12 {
	font-family: Poppins, sans-serif;
}

.heading-12 {
	font-family: Poppins, sans-serif;
}

.paragraph-13 {
	font-family: Poppins, sans-serif;
}

.italic-text {
	font-family: Poppins, sans-serif;
}

.bold-text {
	font-family: Poppins, sans-serif;
}

.paragraph-14 {
	font-family: Poppins, sans-serif;
}

.text-block-3 {
	font-family: Poppins, sans-serif;
}

.text-block-4 {
	font-family: Poppins, sans-serif;
}

.text-block-5 {
	font-family: Poppins, sans-serif;
}

.text-block-6 {
	font-family: Poppins, sans-serif;
}

.text-block-7 {
	font-family: Poppins, sans-serif;
}

.text-block-8 {
	font-family: Poppins, sans-serif;
}

.text-block-9 {
	font-family: Poppins, sans-serif;
}

.text-block-10 {
	font-family: Poppins, sans-serif;
}

.text-block-11 {
	font-family: Poppins, sans-serif;
}

.text-block-12 {
	font-family: Poppins, sans-serif;
}

.text-block-13 {
	font-family: Poppins, sans-serif;
}

.text-block-14 {
	font-family: Poppins, sans-serif;
}

.text-block-15 {
	font-family: Poppins, sans-serif;
}

.paragraph-15 {
	font-family: Poppins, sans-serif;
}

.paragraph-16 {
	font-family: Poppins, sans-serif;
}

.block-quote {
	background-color: transparent;
	box-shadow: -4px 0 0 0 #96653d;
	font-family: Poppins, sans-serif;
	color: #333;
}

.paragraph-17 {
	font-family: Poppins, sans-serif;
}

.paragraph-18 {
	font-family: Poppins, sans-serif;
}

.heading-13 {
	font-family: Poppins, sans-serif;
}

.heading-14 {
	font-family: Poppins, sans-serif;
	color: #726869;
}

.paragraph-19 {
	font-family: Poppins, sans-serif;
}

.heading-15 {
	font-family: Poppins, sans-serif;
}

.paragraph-20 {
	font-family: Poppins, sans-serif;
}

.paragraph-21 {
	font-family: Poppins, sans-serif;
}

.heading-16 {
	font-family: Poppins, sans-serif;
}

.paragraph-22 {
	font-family: Poppins, sans-serif;
}

.heading-17 {
	font-family: Poppins, sans-serif;
}

.paragraph-23 {
	font-family: Poppins, sans-serif;
}

.text-block-16 {
	font-family: Poppins, sans-serif;
}

.text-block-17 {
	font-family: Poppins, sans-serif;
}

.paragraph-24 {
	font-family: Poppins, sans-serif;
}

.heading-18 {
	font-family: Poppins, sans-serif;
}

.paragraph-25 {
	font-family: Poppins, sans-serif;
}

.heading-19 {
	font-family: Poppins, sans-serif;
}

.paragraph-26 {
	font-family: Poppins, sans-serif;
}

.heading-20 {
	font-family: Poppins, sans-serif;
}

.paragraph-27 {
	font-family: Poppins, sans-serif;
}

.heading-21 {
	font-family: Poppins, sans-serif;
}

.heading-22 {
	font-family: Poppins, sans-serif;
}

.paragraph-28 {
	font-family: Poppins, sans-serif;
}

.heading-23 {
	font-family: Poppins, sans-serif;
}

.paragraph-29 {
	font-family: Poppins, sans-serif;
}

.text-block-18 {
	font-family: Poppins, sans-serif;
}

.text-block-19 {
	font-family: Poppins, sans-serif;
}

.paragraph-30 {
	font-family: Poppins, sans-serif;
}

.heading-24 {
	font-family: Poppins, sans-serif;
}

.paragraph-31 {
	font-family: Poppins, sans-serif;
}

.heading-25 {
	font-family: Poppins, sans-serif;
}

.heading-26 {
	font-family: Poppins, sans-serif;
}

.heading-27 {
	font-family: Poppins, sans-serif;
}

.paragraph-32 {
	font-family: Poppins, sans-serif;
}

.heading-28 {
	font-family: Poppins, sans-serif;
}

.paragraph-33 {
	font-family: Poppins, sans-serif;
}

.paragraph-34 {
	font-family: Poppins, sans-serif;
}

.heading-29 {
	font-family: Poppins, sans-serif;
}

.paragraph-35 {
	font-family: Poppins, sans-serif;
}

.heading-30 {
	font-family: Poppins, sans-serif;
}

.paragraph-36 {
	font-family: Poppins, sans-serif;
}

.text-block-20 {
	font-family: Poppins, sans-serif;
}

.text-block-21 {
	font-family: Poppins, sans-serif;
}

.paragraph-37 {
	font-family: Poppins, sans-serif;
}

.heading-31 {
	font-family: Poppins, sans-serif;
}

.paragraph-38 {
	font-family: Poppins, sans-serif;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000003;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="slidecaptionheader-2"] {
	opacity: 0;
	-webkit-transform: scale(0.7000000000000006, 0.7000000000000006);
	-ms-transform: scale(0.7000000000000006, 0.7000000000000006);
	transform: scale(0.7000000000000006, 0.7000000000000006);
}

html.w-mod-js *[data-ix="slide-learn-more"] {
	opacity: 0;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2em;
	}

	.button.header-search-button {
		width: 4.5rem;
		height: 4rem;
		border-radius: 0rem;
		background-image: url('/20150810114905/assets/images/icon_arrow2_right_wht_50.svg');
		background-size: 20px 20px;
		font-size: 1.125rem;
	}

	.container.footer.gdrp {
		padding-top: 1rem;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1em 1rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		text-align: left;
		text-transform: none;
	}

	.nav-link:hover {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.w--current {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 6px 0 0 0 #726869;
	}

	.nav-link.w--current:hover {
		background-color: rgba(0, 0, 0, 0.09);
		color: #fff;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.two-part-mobile.contact-us {
		width: 100%;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.25);
		color: #e5c754;
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		width: 85%;
		padding-bottom: 100vh;
		background-color: #25282a;
		text-align: left;
	}

	.logo {
		font-size: 3.25rem;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.menu-button {
		height: 4.5rem;
		padding: 0px 1.5rem 0px 1rem;
		float: left;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		background-color: transparent;
		box-shadow: none;
		color: #e5c754;
		line-height: 4.5rem;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button.w--open {
		background-color: #96653d;
		color: rgba(0, 0, 0, 0.75);
	}

	.footer-signoff-section {
		padding-top: 2rem;
		padding-bottom: 2.5rem;
		text-align: center;
	}

	.copyright-text {
		margin-bottom: 1rem;
		margin-left: 0.75rem;
	}

	.footer-signoff-grip {
		margin-top: 1rem;
		float: none;
	}

	.header-content-right-wrap {
		margin: 0px;
		padding-bottom: 0rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.hidden-form-field.header-search-field {
		display: block;
		width: 70%;
		height: 4rem;
		padding-left: 1.25rem;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border-style: none;
		border-width: 0px;
		opacity: 1;
		font-size: 1rem;
	}

	.hidden-form-field.header-search-field::-webkit-input-placeholder {
		color: #373a3d;
	}

	.hidden-form-field.header-search-field:-ms-input-placeholder {
		color: #373a3d;
	}

	.hidden-form-field.header-search-field::-ms-input-placeholder {
		color: #373a3d;
	}

	.hidden-form-field.header-search-field::placeholder {
		color: #373a3d;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.right-sidebar-column {
		margin-top: 3rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}

	.section-wrap.inside-content-section {
		padding-top: 570px;
	}

	.secondary-nav-icon {
		opacity: 0.75;
	}

	.secondary-nav-text:hover {
		color: #ddd;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 1.75rem;
		padding-left: 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #fff;
		font-size: 1rem;
		font-weight: 500;
	}

	.secondary-nav-list-link:hover {
		background-color: transparent;
		color: #fff;
	}

	.inside-page-header-content-wrap {
		margin-top: 0.5em;
	}

	.inside-page-header-section {
		height: 0px;
		margin-top: 0px;
	}

	.inside-content-beckground {
		margin-top: 95px;
		margin-bottom: 0rem;
		padding-top: 3rem;
		padding-bottom: 2rem;
		border-right-style: none;
		border-left-style: none;
	}

	.inside-content-beckground.map-section {
		margin-top: 0rem;
		padding-top: 2rem;
		padding-bottom: 3rem;
		border-top-style: none;
		border-bottom-style: none;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.main-content-wrap.no-sidebars {
		max-width: 100%;
	}

	.menu-button-icon {
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		color: #fff;
		font-size: 2rem;
		line-height: 4.5rem;
	}

	.menu-button-text {
		margin-left: 0.125rem;
		float: left;
		color: #fff;
		font-size: 1rem;
		line-height: 4.5rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		max-width: 100%;
		padding-right: 0px;
		padding-left: 0px;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1.05rem;
		padding-bottom: 1.05rem;
		float: right;
		background-color: rgba(0, 0, 0, 0.1);
		background-image: url('/20150810114905/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 1.125rem;
		line-height: 1.375rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.two-part-button-toggle.contact-us {
		display: none;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #25282a;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/20150810114905/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.header-container {
		background-color: #fff;
		background-image: none;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem;
	}

	.header-search-form {
		display: none;
		opacity: 0;
	}

	.header-mobile-search-reveal {
		display: block;
		opacity: 1;
	}

	.footer-logos-section-wrap {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}

	.footer-logo-link-wrap {
		-webkit-box-flex: 0;
		-webkit-flex-grow: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}

	.header-logos-wrap {
		width: 100%;
		margin-right: 10px;
	}

	.header-logo-link {
		max-width: 90px;
	}

	.header-logo-image {
		max-height: 65px;
	}

	.header-logo-image.green-tech-icon {
		max-height: 62px;
	}

	.header-logo-image.gator-icon {
		max-height: 60px;
	}

	.footer-logo-column {
		width: 33.33%;
		max-width: none;
		-webkit-box-flex: 0;
		-webkit-flex-grow: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}

	.rotator {
		height: 750px;
		max-height: 750px;
		background-color: #25282a;
	}

	.slidenav {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;
		display: none;
		height: 40px;
	}

	.slideshow-arrow {
		position: absolute;
		top: 60px;
		width: 60px;
		height: 60px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		font-size: 1.75rem;
		line-height: 20px;
	}

	.slideshow-arrow.slide-arrow-right {
		margin-top: 420px;
	}

	.slideshow-arrow.slide-arrow-left {
		margin-top: 420px;
	}

	.button-2.slide-button {
		height: auto;
	}

	.button-2.slide-button:hover {
		background-color: #5c5c5c;
	}

	.slide-caption-header-wrap {
		border-style: solid;
		border-color: hsla(0, 0%, 100%, 0.15);
	}

	.slide-image-wrap {
		height: 400px;
		background-image: none;
	}

	.slide-image-wrap.slide-image-three {
		height: 500px;
	}

	.slide-image-wrap.slide-image-two {
		height: 500px;
	}

	.slide-image-wrap.slide-image-one {
		height: 500px;
	}

	.slide-image-wrap.slide-image-four {
		height: 500px;
	}

	.rotator-section {
		padding-top: 200px;
	}

	.slide-arrow-icon {
		font-size: 1.5rem;
	}

	.slide-caption-header {
		max-width: 700px;
		font-size: 1.75rem;
		line-height: 2.35rem;
		text-decoration: none;
	}

	.slide {
		background-color: transparent;
	}

	.slide-caption-wrapper {
		position: static;
		top: 0%;
		display: block;
		overflow: visible;
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 2rem 0rem;
		background-color: #25282a;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-caption-inner-wrap {
		padding: 1rem 0rem 0.5rem;
	}

	.search-icon-button.header-search-button {
		width: 4.5rem;
		height: 4rem;
		font-size: 1.125rem;
	}

	.case-notes-title {
		font-size: 2.5rem;
	}

	.note-div {
		min-height: 235px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.case-title {
		min-height: 65px;
		padding-bottom: 0.25rem;
		font-size: 1.3rem;
		line-height: 1.5rem;
	}

	.description {
		margin-bottom: 1rem;
		padding-left: 1rem;
		font-size: 0.9rem;
		line-height: 1.3rem;
	}

	.news-title {
		font-size: 2.5rem;
	}

	.footer-column {
		width: 32%;
	}

	.footer-column.middle {
		width: 36%;
	}

	.footer-column.linkedin {
		width: 32%;
	}

	.link-block {
		width: 45px;
		height: 45px;
		background-size: 20px 20px;
	}

	.cookie-div {
		width: 90%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.cookie-text-para {
		width: 90%;
		margin-bottom: 0.25rem;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.gdrp-link {
		margin-right: 1rem;
	}

	.exit-icon-div {
		right: 1rem;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.firm-update-div {
		min-height: 235px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.case-date {
		margin-bottom: 0.25rem;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 1.75em;
	}

	h2 {
		font-size: 1.5em;
		line-height: 1.5em;
	}

	h3 {
		font-size: 1.375em;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125em;
		line-height: 1.5em;
	}

	.button.header-search-button {
		width: 4.25rem;
		height: 3.5rem;
	}

	.button.add-to-my-links-button {
		padding-left: 1.625rem;
		background-size: 12px 12px;
		font-size: 0.625rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.nav-link {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.logo {
		padding-left: 0px;
	}

	.footer-signoff-section {
		background-color: #000;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(53, 53, 53, 0.5)), to(rgba(53, 53, 53, 0.5))), url('/20150810114905/assets/images/black-orchid.png');
		background-image: linear-gradient(180deg, rgba(53, 53, 53, 0.5), rgba(53, 53, 53, 0.5)), url('/20150810114905/assets/images/black-orchid.png');
		background-position: 0px 0px, 50% 50%;
	}

	.header-content-right-wrap {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.breadcrumb-list {
		font-size: 0.625rem;
		letter-spacing: 0.02em;
	}

	.header-menu-wrap {
		width: 100%;
	}

	.hidden-form-field.header-search-field {
		height: 3.5rem;
		font-size: 1rem;
	}

	.secondary-nav-list {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list-item {
		width: 100%;
		max-width: 100%;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.section-wrap.inside-content-section {
		padding-right: 0px;
		padding-left: 0px;
	}

	.secondary-nav-list-link {
		padding-right: 1.25rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		font-size: 0.875rem;
	}

	.inside-page-header-content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0.5em;
		margin-right: 10px;
		margin-left: 10px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inside-page-header-section {
		margin-right: 0px;
		margin-left: 0px;
	}

	.inside-content-beckground {
		margin-top: 95px;
		margin-bottom: 0rem;
	}

	.inside-content-beckground.map-section {
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-bottom: 2.5rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.menu-button-text {
		font-size: 0.875rem;
	}

	.header-menu-inside-wrap {
		width: 100%;
	}

	.intro-text-style {
		font-size: 1.125em;
	}

	.post-item-featured-image-link {
		max-height: 240px;
	}

	.two-part-button-toggle {
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.header-container {
		padding-top: 0em;
		padding-bottom: 0em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-logos-section-wrap {
		margin-right: -5px;
		margin-left: -5px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}

	.footer-logo-link-wrap {
		text-align: center;
	}

	.header-logos-wrap {
		width: 100%;
		max-width: 100%;
		margin: 0px;
		padding: 15px 2%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		border-top: 1px solid #ddd;
	}

	.header-logo-link {
		margin-right: 4%;
		margin-left: 4%;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.footer-logo-column {
		-webkit-box-flex: 0;
		-webkit-flex-grow: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.rotator {
		height: auto;
	}

	.slidenav {
		height: 40px;
		background-color: transparent;
	}

	.slideshow-arrow {
		top: -125px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.button-2.slide-button {
		padding-top: 0.4rem;
	}

	.slide-caption-header-wrap {
		min-width: 400px;
	}

	.slide-image-wrap {
		height: 345px;
	}

	.slide-image-wrap.slide-image-three {
		background-size: cover;
	}

	.slide-image-wrap.slide-image-one {
		background-size: cover;
	}

	.slide-image-wrap.slide-image-four {
		background-size: cover;
	}

	.slide-arrow-icon {
		font-size: 1.125rem;
	}

	.slide-caption-header {
		font-size: 1.5rem;
		line-height: 2.05rem;
	}

	.slide-caption-wrapper {
		padding-right: 1rem;
		padding-bottom: 3rem;
		padding-left: 1rem;
	}

	.slide-mask {
		background-color: transparent;
	}

	.search-icon-button.header-search-button {
		width: 4.25rem;
		height: 3.5rem;
	}

	.case-and-news-section {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.case-notes-div {
		width: 100%;
		padding-bottom: 4rem;
	}

	.notes-detail-div {
		border-right-style: none;
	}

	.note-div {
		min-height: auto;
		padding-bottom: 0rem;
	}

	.case-title {
		min-height: 45px;
		padding-bottom: 0rem;
	}

	.view-more-link {
		margin-top: 0rem;
	}

	.link-block {
		width: 42px;
		height: 42px;
		background-size: 18px 18px;
	}

	.map-image-div {
		width: 100%;
		padding-bottom: 0rem;
	}

	.cookie-div {
		width: 85%;
	}

	.cookie-text-para {
		width: 85%;
	}

	.quicklinks-into-columns {
		grid-template-areas: "." "." "." "." "." "." ".";
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto auto auto auto auto auto;
		grid-template-rows: auto auto auto auto auto auto auto;
	}

	.firm-update-div {
		min-height: auto;
		padding-bottom: 0rem;
	}
}

@media (max-width: 479px) {
	.container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.footer {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.container.footer.gdrp {
		padding-top: 3rem;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.logo {
		margin-right: 0px;
		margin-left: 0px;
		font-size: 3rem;
	}

	.footer-signoff-list-item {
		margin-bottom: 0.5rem;
	}

	.hidden-form-field.header-search-field {
		font-size: 0.875rem;
	}

	.secondary-nav-list {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list-item {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.section-wrap.inside-content-section {
		padding-top: 405px;
	}

	.secondary-nav-list-link {
		padding-right: 1.5rem;
		padding-left: 0.5rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		font-size: 0.75rem;
	}

	.inside-content-beckground {
		margin-top: 30px;
	}

	.menu-button-text {
		font-size: 0.75rem;
	}

	.footer-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.link.post-pagination-link.next-page {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
	}

	.post-item-featured-image-link {
		max-height: 160px;
	}

	.post-pagination-wrap {
		text-align: center;
	}

	.post-pagination-text {
		width: 100%;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.header-container {
		background-image: none;
	}

	.logo-subtext {
		margin-left: 11px;
		font-size: 0.625rem;
	}

	.footer-logo-link-wrap {
		margin: 15px 5px;
	}

	.footer-logo-image {
		max-width: 130px;
	}

	.footer-logo-image.sf-logo-image {
		max-width: 80px;
	}

	.footer-logo-image.gator-logo-image {
		max-width: 100px;
	}

	.footer-logo-image.absolute-tech-logo-image {
		max-width: 55px;
	}

	.footer-logo-image.greentech-logo-image {
		max-width: 110px;
	}

	.footer-logo-image.absolute-concrete-logo-image {
		max-width: 130px;
	}

	.header-logo-link {
		margin-right: 3%;
		margin-left: 3%;
	}

	.slideshow-arrow {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slideshow-arrow.slide-arrow-right {
		top: 0.25rem;
		width: 40px;
		height: 40px;
		margin-top: 150px;
		margin-right: 1rem;
	}

	.slideshow-arrow.slide-arrow-left {
		top: 0.25rem;
		width: 40px;
		height: 40px;
		margin-top: 150px;
		margin-left: 1rem;
	}

	.button-2.slide-button {
		height: 38px;
		padding-top: 6px;
		padding-bottom: 6px;
		font-size: 0.9rem;
	}

	.slide-caption-header-wrap {
		min-width: 275px;
	}

	.slide-image-wrap {
		height: 200px;
	}

	.slide-image-wrap.slide-image-three {
		height: 275px;
	}

	.slide-image-wrap.slide-image-two {
		height: 275px;
	}

	.slide-image-wrap.slide-image-one {
		height: 275px;
	}

	.rotator-section {
		padding-top: 200px;
	}

	.slide-caption-header {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 1.25rem;
		line-height: 1.6rem;
	}

	.case-notes-title {
		font-size: 2.4rem;
	}

	.case-title {
		padding-bottom: 0.25rem;
		font-size: 1.15rem;
		line-height: 1.35rem;
	}

	.footer-column {
		width: 100%;
		padding-bottom: 2rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.finley-law-firm-title {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.address {
		text-align: center;
	}

	.quicklinks-div {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.link-block {
		width: 50px;
		height: 50px;
		border-radius: 3rem;
		background-color: #726869;
		background-image: url('/20150810114905/assets/images/linkedin_white.svg');
		background-position: 50% 50%;
		background-size: 22px 22px;
		background-repeat: no-repeat;
		-webkit-transition-duration: 450ms;
		transition-duration: 450ms;
	}

	.link-block:hover {
		background-color: #96653d;
	}

	.gdrp-section {
		display: block;
	}

	.cookie-div {
		width: 100%;
		min-width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cookie-text-para {
		margin-bottom: 0.5rem;
		padding-right: 0rem;
		padding-left: 0rem;
		font-size: 0.612rem;
		line-height: 1.075rem;
	}

	.gdrp-link {
		margin-right: 0rem;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.cookie-button.cookies {
		margin-right: 0rem;
	}

	.quicklinks-into-columns {
		justify-items: center;
		grid-row-gap: 8px;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

#w-node-d25032a59d62-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 767px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d25032a59d62-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-ebe777b1c445-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
}

@media screen and (max-width: 991px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 767px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ebe777b1c445-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-7dcde502731b-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-7dcde502731b-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-156051137e02-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-156051137e02-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 5;
		grid-row-start: 5;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-65d5a0b823bb-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 3;
}

@media screen and (max-width: 767px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-65d5a0b823bb-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 4;
		grid-row-start: 4;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-e513b2ae672f-86ecda53 {
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-column-span: 1;
	grid-column-end: 3;
	-ms-grid-row: 3;
	grid-row-start: 3;
	-ms-grid-row-span: 1;
	grid-row-end: 4;
}

@media screen and (max-width: 767px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: start;
		justify-self: start;
	}
}

@media screen and (max-width: 479px) {
	#w-node-e513b2ae672f-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 6;
		grid-row-start: 6;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

#w-node-50824477ecc9-86ecda53 {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row: 4;
	grid-row-start: 4;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-row-span: 1;
	grid-row-end: 5;
}

@media screen and (max-width: 767px) {
	#w-node-50824477ecc9-86ecda53 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 7;
		grid-row-start: 7;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
	}
}

/************* Webflow Inline Styles ******************/
/****************************************************/
html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

.footer-section a[href^=tel] {
	-webkit-transition: all 150ms ease;
	color: #96653d;
	font-weight: 400;
	letter-spacing: 0.01em;
	text-decoration: none;
}

input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input::-webkit-input-placeholder {
	color: #ccc !important;
}

input::-moz-placeholder {
	color: #ccc !important;
}

input:-moz-placeholder {
	color: #ccc !important;
}

input:-ms-input-placeholder {
	color: #ccc !important;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
@media (min-width: 992px) {
	.header-search-form {
		display: flex !important;
		height: auto !important;
		opacity: 1 !important;
	}
}

/****************** Accesible Menu *******************/
/*****************************************************/
.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.accesible-navigation-menu ul li {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1px;
	margin-left: 0px;
	margin-bottom: 0px;
	margin-top: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-bottom-style: none;
	box-shadow: 0 -3px 0 0 transparent, 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.accesible-navigation-menu ul li a {
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	width: 100%;
	border: 0px;
	padding: 1rem 0.5rem;
}

.accesible-navigation-menu ul li:hover,
.accesible-navigation-menu ul li a.active {
	background-color: #000;
	box-shadow: 0 -3px 0 0 #96653d, 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
}

.accesible-navigation-menu ul li .container-wrapper {
	display: none;
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background-color: #25282a;
}

.mobile-navigation-menu li {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.mobile-navigation-menu li a.nav-a {
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	letter-spacing: 0.03em;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #fff;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	letter-spacing: 0.03em;
	padding-top: 16px;
}


.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li.active a.nav-a {
	background-color: rgba(0, 0, 0, 0.25);
}

.mm-listitem:after {
	border-bottom: 0px;
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before,
.mm-listitem:after,
.mm-listview {
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 70px;
	background-color: rgba(0, 0, 0, 0.1);
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth):hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 70px;
}

.mm-btn_next:after {
	right: 34px;
}

.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listitem_selected > span {
	background: none;
}

.mm-navbar {
	height: 50px;
	text-align: left;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
	left: 22px;
	top: 9px;
}

.mm-navbar__title {
	padding-left: 10px;
}

/********************** Slideshow *************************/
/**********************************************************/
.slide .slide-caption-header {
	opacity: 0;
	transform: scaleX(0.7) scaleY(0.7) scaleZ(1);
}

.slide.cycle-slide-active .slide-caption-header {
	opacity: 1;
	transform: scaleX(1) scaleY(1) scaleZ(1);
	transition: opacity 500ms ease 0s, transform 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.slide .slide-button {
	opacity: 0;
}

.slide.cycle-slide-active .slide-button {
	transition: opacity 500ms ease 0s;
	opacity: 1;
	transition-delay: 1s;
}

/******************** Homepage **********************/
/*****************************************************/
h2.case-notes-title,
h2.news-title {
	color: #96653d;
	margin-bottom: 1rem;
	padding-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	font-size: 3rem;
	line-height: 3.25rem;
}

h2.case-notes-title a,
h2.news-title a {
	text-decoration: none;
	border-bottom: 1px solid #ddd;
	font-family: Poppins, sans-serif;
	font-size: 3rem;
	line-height: 3.25rem;
	color: #96653d;
}

h2.case-notes-title a:hover,
h2.news-title a:hover {
	border-bottom-color: transparent;
	color: #25282a;
	text-decoration: none;
}

.gmap_canvas iframe {
	border: 0px;
	max-width: 100%;
}

.news-detail-div,
.notes-detail-div {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	min-height: 200px;
	align-items: flex-start;
	display: block;
	padding-left: 7.5%;
	padding-right: 7.5%;
}

.map-image-div iframe {
	width: 100%;
}

@media (max-width: 991px) {
	h2.case-notes-title a,
	h2.news-title a {
		font-size: 2.5rem;
	}
}

@media (max-width: 767px) {
	.news-detail-div,
	.notes-detail-div {
		min-height: auto;
	}
}
/****************** Inside Page *******************/
/*************************************************/
.breadcrumb-list .breadcrumb-list-item:last-child .breadcrumb-divider {
	display: none;
}

.left-nav-list-link.active,
.left-nav-nested-list-link.active {
	color: #000;
}

.right-column .right-sidebar-column {
	padding-left: 10px;
	padding-right: 10px;
}

.right-column .main-content-column {
	padding-left: 0px;
	padding-right: 0px;
}

.right-column .main-content-column-nested {
	padding-left: 10px;
	padding-right: 10px;
}

img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/******************* Right Sidebar *******************/
/*****************************************************/
.right-sidebar-column .box {
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 5px;
	padding-left: 5px;
	border-top: 2px solid #96653d;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	font-family: Poppins, sans-serif;
	font-size: 1.375em;
	line-height: 1.5rem;
	font-weight: 400;
	color: #726869;
	letter-spacing: 0.01em;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box td {
	padding-bottom: 1rem;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.right-sidebar-column .box {
		padding-left: 0px;
		padding-right: 0px;
		margin-right: 0px;
		margin-left: 0px;
	}
}

/*********************** Footer **********************/
/*****************************************************/
.phone-link.desktop {
	display: inline;
	color: #96653d;
	font-weight: 400;
	letter-spacing: 0.01em;
	text-decoration: none;
}

.phone-link.mobile {
	display: none;
}

.quicklinks-into-columns {
	display: block;
}

.quicklinks-into-columns td {
	padding-bottom: 8px;
	padding-right: 15px;
	min-width: 125px;
}

.quicklinks-into-columns td a {
	display: inline-block;
	width: 100%;
}

.quicklinks-into-columns td:nth-child(even) {
	padding-right: 0px;
}

#GRIPFooterLogo {
	float: right;
	line-height: 11px;
	padding-top: 0px !important;
}

#GRIPFooterLogo a {
	text-decoration: none;
	border: 0px;
}

@media (max-width: 991px) {
	.phone-link.desktop {
		display: none;
	}

	.phone-link.mobile {
		display: inline;
	}

	#GRIPFooterLogo {
		float: none;
		margin: 20px auto 0px auto;
	}
}

@media (max-width: 767px) {
	.quicklinks-into-columns td {
		display: block;
	}

	.quicklinks-into-columns td a {
		width: auto;
	}
}


@media (max-width: 479px) {
	.quicklinks-into-columns td {
		text-align: center;
		padding-right: 0px;
	}

	.footer-column.middle {
		padding-bottom: 0px;
	}
}


/****************** Inside Page Styles ***************/
/*****************************************************/
/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	border: 0px !important;
	line-height: 0;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.6rem 1rem !important;
	border: 2px solid #0077c8 !important;
	line-height: 1.1rem;
	box-shadow: 0 0 5px #000;
	z-index: 5000;
}

/* headings */
h1 {
	font-family: Poppins, sans-serif;
	font-weight: 400;
}

h2,
h3,
h4,
h5,
h6 {
	font-family: Poppins, sans-serif;
}

/* heading links */
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	font-family: Poppins, sans-serif;
	color: #96653d;
	font-weight: 400;
}

h1 a:hover,
h4 a:hover {
	color: #96653d;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

h5 a:hover {
	text-decoration: none;
	border-bottom: 1px solid transparent;
	color: #25282a;
}

h2 a:hover,
h3 a:hover,
h6 a:hover {
	text-decoration: none;
	color: #000;
	border-bottom: 1px solid transparent;
}

p {
	font-family: Poppins, sans-serif;
}

/* buttons */
button[type="submit"],
.primary,
.secondary,
.tertiary,
.cms_form_button.primary,
.cms_form_button.secondary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button {
	padding: 0.5em 1.3em;
	border: 0px;
	background-color: #96653d;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 10px;
}

button[type="submit"]:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
.cms_form_button.primary:hover,
.cms_form_button.secondary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	color: #fff;
}

.secondary,
.tertiary,
.cms_form_button.secondary,
.cms_form_button.tertiary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	background-color: #373a3d !important;
	color: #fff;
	font-weight: 400;
}

button[type="submit"]:hover,
.primary:hover,
.cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	background-color: #664428 !important;
}


.secondary:hover,
.tertiary:hover,
.cms_form_button.secondary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #000 !important;
}

/* messages */
#message.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #96653d;
	font-family: Poppins, sans-serif;
	color: #fff;
}

#message.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c30a0f;
	color: #fff;
}

#message.success *,
#message.error * {
	color: #fff;
}

#message.success p:last-child,
#message.error p:last-child {
	margin-bottom: 0px;
}

/*tables*/
table.styled {
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: normal;
	text-transform: none;
	font-size: 1em;
	font-family: Oswald;
	letter-spacing: 0.07em;
	color: white;
	background-color: black;
	border-bottom: 2px solid white;
	padding: 0.625em 0.625rem;
	text-align: left;
}

table.styled th {
	font-weight: normal;
	font-size: 1em;
	font-family: Oswald;
	letter-spacing: 0.02em;
	padding: 0.5em 0.625rem;
	background-color: #96653d;
	border-bottom: 2px solid white;
	color: white;
	text-align: left;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375em 0.625rem;
	font-size: 0.9375em;
	line-height: 1.4125em;
}

table.styled.striped tr:nth-child(even) {
	background: #e9e9e9;
}

table.styled.striped tr:nth-child(odd) {
	background: ghostwhite;
}

/* forms */
.inside-content-section input[type="text"],
.inside-content-section input[type="tel"],
.inside-content-section input[type="password"],
.inside-content-section input[type="email"],
.inside-content-section input[type="search"],
.inside-content-section select,
.inside-content-section textarea {
	padding: 0.8rem 10px 0.8rem 1.25rem;
	border-top-right-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
	background-color: #fff;
	font-family: Poppins, sans-serif;
	font-size: 0.75rem;
	border: 1px solid #cccccc;
}

.inside-content-section textarea {
	resize: vertical;
}

.inside-content-section label,
.inside-content-section .cms_label {
	font-weight: 700;
}

@media (max-width: 991px) {
	.inside-content-section input[type="text"],
	.inside-content-section input[type="tel"],
	.inside-content-section input[type="password"],
	.inside-content-section input[type="email"],
	.inside-content-section input[type="search"],
	.inside-content-section select,
	.inside-content-section textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-color: transparent;
	box-shadow: -4px 0 0 0 #96653d;
	font-family: Poppins, sans-serif;
	color: #333;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: #ddd;
	border: 0px;
	color: #ddd;
	border-top: 1px solid;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/******************** Modules **********************/
/***************************************************/
/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	border-top: 1px solid #ddd;
	margin-bottom: 0.7rem;
	margin-top: 1.5rem;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .description {
	padding: 0px;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/* Submission Forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 0.8rem 10px 0.8rem 1.25rem;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	font-weight: normal;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	background-color: #fff;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	background-color: #fff;
	font-family: inherit;
}


/* search */
.search-collection.collection > .node.search-node {
	border-top: 1px solid #ddd;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.search-collection.collection > .node.search-node:first-child {
	border-top: 0px;
	margin-top: 0.5rem;
}

@media (max-width: 991px) {
	.module-search input[type="search"] {
		margin-bottom: 20px;
	}
}

/* Accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #ddd;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #000;
	font-size: 1.2rem;
	line-height: 1.7rem;
	font-weight: 700;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #eb0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/* gdpr */
#cookie-consent-container {
	background-color: #96653d;
}

#cookie-consent-container:not(.minimized) {
	background-color: #000;
	border-top: 4px none #d3d3d3;
	padding: 1rem;
	box-shadow: 0 -13px 0 0 rgba(179, 182, 183, 0.75);
	display: flex;
	justify-content: center;
}

#cookie-consent-container .consent-disclaimer {
	width: calc(100% - 400px);
	padding-right: 1rem;
	padding-left: 1rem;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.3rem;
	max-width: 840px;
}

#cookie-consent-container .consent-actions {
	width: 400px;
	order: 2;
}

#cookie-consent-container .consent-actions a {
	font-family: Poppins, sans-serif;
	color: #fff;
	border-bottom: 1px solid #ddd;
	text-align: center;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#cookie-consent-container .consent-actions a:hover {
	color: #fff;
	border-bottom: 1px solid transparent;
	text-decoration: none;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#cookie-consent-container .consent-actions a.consent-all-trigger {
	font-size: 1rem;
	line-height: 1.5rem;
	border: 0px;
	background-color: #373a3d;
	font-family: Poppins, sans-serif;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 9px 16px 7px;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	border: 2px solid #373a3d;
	font-size: 0.9375rem;
}

#cookie-consent-container .consent-actions a.consent-all-trigger:hover {
	border-color: #726869;
	background-color: #000;
	color: #fff;
}

#cookie-consent-container .consent-actions a.consent-dismiss-trigger img {
	width: 31px;
	height: 31px;
}

@media (max-width: 991px) {
	#cookie-consent-container:not(.minimized) {
		flex-wrap: wrap;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100%;
		max-width: none;
		padding-right: 100px;
	}

	#cookie-consent-container .consent-actions .consent-dismiss-trigger-container {
		text-align: right;
		position: absolute;
		right: 0;
		top: 7px;
	}
}

@media (max-width: 479px) {
	#cookie-consent-container .consent-disclaimer {
		padding-right: 0px;
		font-size: 0.612rem;
		line-height: 1.075rem;
	}

	#cookie-consent-container .consent-actions {
		width: 250px;
	}

	#cookie-consent-container .consent-actions a {
		margin-top: 0px;
	}
}

/* add this */
.at-expanding-share-button a {
	text-decoration: none;
	border-bottom: 0px;
}


.at-expanding-share-button[data-position=bottom-left] {
	bottom: 1.93rem !important;
}


/***************** Content Boxes *******************/
/***************************************************/
/* news */
#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 0.5rem;
	padding: 1rem;
	border: 1px solid #96653d;
	background-color: rgba(156, 167, 175, 0.3);
	padding-bottom: 1.5rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
	font-family: Poppins, sans-serif;
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-weight: 500;
	color: #333;
	margin-top: 0px;
	margin-bottom: 0.5rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a {
	font-family: Poppins, sans-serif;
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-weight: 500;
	border: 0px;
	text-decoration: none;
	color: #333;
}

#news_module.homepage_contentbox .cms_list_item .cms_date {
	margin-bottom: 0.5rem;
	padding-top: 0.2rem;
	padding-left: 1.5rem;
	background-image: url("/20150810114905/assets/images/calendar-icon_brown-2.svg");
	background-position: 0% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	line-height: 1.5rem;
	font-weight: 600;
	color: #333;
	font-size: 1rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	margin: 0px;
	font-family: Poppins, sans-serif;
	line-height: 1.5rem;
	font-weight: 600;
	color: #333;
	font-size: 1rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_description {
	font-family: Poppins, sans-serif;
}

#news_module.homepage_contentbox > a:last-child,
#case_notes_module.contentbox > a:last-child {
	margin-top: 1rem;
	padding: 0.5rem 1rem 0.4rem 1rem;
	border-style: solid;
	border-width: 1px;
	border-color: #d3d3d3;
	font-family: Poppins, sans-serif;
	text-align: center;
	display: block;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 400;
	margin-left: auto;
	margin-right: auto;
	width: 120px;
	margin-top: 2rem;
}

#case_notes_module.contentbox > a:last-child {
	width: 195px;
}

#news_module.homepage_contentbox > a:last-child:hover,
#case_notes_module.contentbox > a:last-child:hover {
	background-color: #25282a;
	color: #d3d3d3;
}

#news_module.homepage_contentbox .more-container {
	margin: 0px;
}

#news_module.homepage_contentbox .more-container a {
	border-bottom-color: #adadad;
	color: #805634;
}

#news_module.homepage_contentbox .more-container a:hover {
	border-bottom-color: transparent;
	color: #25282a;
	text-decoration: none;
}

@media (max-width: 991px) {
	#news_module.homepage_contentbox .cms_list_item .cms_title h3,
	#news_module.homepage_contentbox .cms_list_item .cms_title h3 a {
		margin-bottom: 0.25rem;
		font-size: 1.3rem;
		line-height: 1.5rem;
	}

	#news_module.homepage_contentbox .cms_list_item .cms_date {
		margin-bottom: 0.25rem;
	}

	#news_module.homepage_contentbox .cms_list_item .cms_description {
		font-size: 0.9rem;
		line-height: 1.3rem;
	}
}

@media (max-width: 767px) {
	#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
		margin-bottom: 0rem;
	}

	#news_module.homepage_contentbox > a:last-child,
	#case_notes_module.contentbox > a:last-child {
		margin-top: 1.5rem;
		font-size: 0.9375rem;
		line-height: 1.5em;
	}
}

@media (max-width: 479px) {
	#news_module.homepage_contentbox .cms_list_item .cms_title h3,
	#news_module.homepage_contentbox .cms_list_item .cms_title h3 a {
		padding-bottom: 0.25rem;
		font-size: 1.15rem;
		line-height: 1.35rem;
	}
}

/* form */
#submissionforms_module.homepage_contentbox {
	padding: 10px;
}

#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}


#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox .radio_wrapper .cms_table_fullwidth label {
	font-weight: normal;
}


/* case notes */
.case-notes.contentbox .case-note {
	margin-bottom: 0.5rem;
	padding: 1rem;
	border: 1px solid #96653d;
	background-color: hsla(0, 0%, 82.7%, 0.35);
	padding-bottom: 1.5rem;
}

.case-notes.contentbox .case-note .title {
	font-family: Poppins, sans-serif;
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-weight: 500;
	color: #333;
	margin-top: 0px;
	margin-bottom: 0.5rem;
}

.case-notes.contentbox .case-note .title a {
	font-family: Poppins, sans-serif;
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-weight: 500;
	border: 0px;
	text-decoration: none;
	color: #333;
}

.case-notes.contentbox .case-note .date {
	margin-bottom: 0.5rem;
	padding-top: 0.2rem;
	padding-left: 1.5rem;
	background-image: url("/20150810114905/assets/images/calendar-icon_brown-2.svg");
	background-position: 0% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	line-height: 1.5rem;
	font-weight: 600;
	color: #333;
	font-size: 1rem;
}

.case-notes.contentbox .case-note .content {
	font-family: Poppins, sans-serif;
	margin-bottom: 0.75rem;
}

.case-notes.contentbox .case-note .more a {
	border-bottom-color: #adadad;
	color: #805634;
}

.case-notes.contentbox .case-note .more a:hover {
	border-bottom-color: transparent;
	color: #25282a;
	text-decoration: none;
}

@media (max-width: 991px) {
	.case-notes.contentbox .case-note .title,
	.case-notes.contentbox .case-note .title a {
		margin-bottom: 0.25rem;
		font-size: 1.3rem;
		line-height: 1.5rem;
	}

	.case-notes.contentbox .case-note .content {
		font-size: 0.9rem;
		line-height: 1.3rem;
	}
}

@media (max-width: 479px) {
	.case-notes.contentbox .case-note .title,
	.case-notes.contentbox .case-note .title a {
		padding-bottom: 0.25rem;
		font-size: 1.15rem;
		line-height: 1.35rem;
	}
}

/********************** Law Firm *********************/
/*****************************************************/
/* Practice Area - List View */
#module-practiceareas.list-view .practice-area {
	margin: 0 0 1.5em;
	padding: 0 0 1.5em;
}

#module-practiceareas.list-view .practice-area .area-image {
	display: none;
}

#module-practiceareas.list-view .practice-area .area-children a {
	border-bottom: 1px solid transparent;
	display: block;
	width: 100%;
	margin-top: 10px;
}

#module-practiceareas.list-view .practice-area .area-children a:before {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-left: 7px solid #96653d;
	content: "";
	display: inline-block;
}

/* Practice Area - Thumbnail View */
#module-practiceareas.thumbnail-view .main-list-image-column,
#module-practiceareas.thumbnail-view .main-list-summary-column,
#module-practiceareas.thumbnail-view .main-list-related-areas-column {
	width: 100%;
	float: none;
	display: block;
	margin-bottom: 7px;
	padding-right: 0px;
	padding-left: 0px;
}

#module-practiceareas.thumbnail-view .main-list-image-column {
	margin-top: 10px;
}

#module-practiceareas.thumbnail-view .main-list-related-areas-column {
	margin-bottom: 0px;
}

#module-practiceareas.thumbnail-view .main-list-related-areas-column .main-list-item-related-areas-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

#module-practiceareas.thumbnail-view .main-list-related-areas-column .main-list-item-related-areas-list a {
	border-bottom: 1px solid transparent;
}

#module-practiceareas.thumbnail-view .main-list-related-areas-column .main-list-item-related-areas-list a:before {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-left: 7px solid #96653d;
	content: "";
	display: inline-block;
	margin-right: 5px;
}

/* Practice Area - Details */
#module-practiceareas.details-view {
	margin-top: 0px;
}

#module-practiceareas.details-view h2 {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 5px solid #ddd;
	font-size: 1.25rem;
	line-height: 26px;
	margin-top: 0px;
}

#module-practiceareas.details-view #area-image {
	height: 300px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 20px;
	float: none;
	width: 100%;
	margin-left: 0px;
}

#module-practiceareas.details-view #area-main {
	width: 66.66666667%;
	float: left;
	padding-right: 30px;
}

#module-practiceareas.details-view #area-sidebar {
	width: 33.33333333%;
	float: left;
}

#module-practiceareas.details-view #area-sidebar .area-sidebar-box {
	margin-bottom: 20px;
}


@media (max-width: 767px) {
	#module-practiceareas.details-view #area-main,
	#module-practiceareas.details-view #area-sidebar {
		float: none;
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
		margin-bottom: 20px;
	}
}


/* Attorneys */
#module-attorneys {
	margin: 0px;
}

#module-attorneys.alpha table a,
#module-attorneys.practice table a {
	color: #333;
	border-bottom: 1px solid transparent;
}

#module-attorneys.alpha table tr:hover td,
#module-attorneys.practice table tr:hover td {
	background-color: #cfcfcf;
}

#module-attorneys.alpha table .name,
#module-attorneys.practice table .name {
	width: 30%;
}

#module-attorneys.alpha table .phone,
#module-attorneys.practice table .phone {
	width: 20%;
}

#module-attorneys.alpha table .email,
#module-attorneys.practice table .email {
	width: 40%;
}

#module-attorneys table .column-headers:hover td,
#module-attorneys table.alpha .column-headers:hover td {
	background-color: transparent !important;
}

#module-attorneys.alpha #letter-navigation,
#module-attorneys.practice .practice-area-sub-header,
#publications-toggler {
	background-image: none;
}

@media (max-width: 767px) {
	#module-attorneys.alpha table tr:hover td,
	#module-attorneys.practice table tr:hover td {
		background-color: transparent;
	}

	#module-attorneys.alpha table .name,
	#module-attorneys.practice table .name {
		font-weight: bold;
	}

	#module-attorneys.alpha table td,
	#module-attorneys.alpha table tr td,
	#module-attorneys.practice table td,
	#module-attorneys.practice table tr td {
		display: block;
		padding-left: 0px;
		padding-right: 0px;
		text-align: left;
		width: 100% !important;
	}

	#module-attorneys table .column-headers {
		display: none;
	}
}

/* Attorneys - Alphabetical */
#module-attorneys.alpha #letter-navigation {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #cfcfcf;
	border-bottom: 1px solid #cfcfcf;
	margin-bottom: 30px;
}

#module-attorneys.alpha #letter-navigation a {
	border-bottom: 1px solid transparent;
	line-height: 0.9rem;
	font-weight: bold;
	color: #96653d;
}

#module-attorneys.alpha table tr td {
	height: auto;
}

#module-attorneys.alpha table thead tr td {
	color: #726869;
	text-transform: none;
	font-weight: bold;
}

#module-attorneys.alpha .alpha-indicator {
	color: #96653d;
	font-size: 1.5rem;
}

#module-attorneys.alpha .alpha-indicator {
	width: 10%;
}

#module-attorneys.alpha .column-headers td {
	font-size: 0.9375rem;
}

@media (max-width: 767px) {
	#module-attorneys.alpha #letter-navigation {
		display: none;
	}

	#module-attorneys.alpha .alpha-indicator {
		padding-bottom: 7px;
	}
}


/* Attorneys - By Practice */
#module-attorneys.practice .section-header {
	border-bottom: 0px;
	color: #96653d;
	font-size: 1.125rem;
	text-transform: none;
	padding: 0px;
	font-weight: bold;
}

#module-attorneys.practice .practice-area-sub-header {
	text-transform: none;
	font-weight: bold;
	margin: 0px;
	font-size: 0.9375rem;
}

#module-attorneys.practice .column-headers td {
	color: #726869;
	text-transform: none;
	font-weight: bold;
	padding: 10px 0px;
	font-size: 0.9375rem;
}

#module-attorneys.practice tr.nohl:hover td {
	background-color: transparent;
}

@media (max-width: 767px) {
	#module-attorneys.practice table td {
		padding-bottom: 0px !important;
	}

	#module-attorneys.practice td.name {
		padding-top: 7px;
	}

	#module-attorneys.practice .practice-area-sub-header {
		padding-bottom: 0px;
	}

	#module-attorneys.practice table tr.practice-area td:last-child {
		padding-bottom: 7px !important;
	}
}

/* Attorneys - By Photo */
#module-attorneys.photo {
	display: flex;
	flex-wrap: wrap;
}

#module-attorneys.photo .attorney {
	width: 33%;
	margin: 0px 0px 30px 0px;
	padding-right: 5px;
	padding-left: 5px;
	text-align: center;
}

#module-attorneys.photo .attorney .image {
	margin-bottom: 10px;
	border: 1px solid #a8a8a8;
	width: 250px;
	height: auto;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}

#module-attorneys.photo .attorney .image img {
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	height: 100%;
}

@media (max-width: 991px) {
	#module-attorneys.photo {
		margin-top: 30px;
	}
}

@media (max-width: 767px) {
	#module-attorneys.photo .attorney {
		width: 50%;
	}
}


@media (max-width: 479px) {
	#module-attorneys.photo .attorney {
		width: 100%;
	}
}


/* Attorneys - Details */
#module-attorneys.profile h2,
#module-attorneys.profile .content-box h2 {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 5px solid #ddd;
	font-size: 1.25rem;
	line-height: 26px;
	margin-top: 0px;
	color: #726869;
	text-transform: none;
}

#module-attorneys.profile .profile-info,
#module-attorneys.profile .profile-content {
	margin-bottom: 20px;
	display: inline-block;
	width: 100%;
}

#module-attorneys.profile .profile-left {
	width: 66.66666667%;
	float: left;
	padding-right: 30px;
}

#module-attorneys.profile .profile-right {
	width: 33.33333333%;
	float: right;
}

#module-attorneys.profile .content-box {
	margin-bottom: 20px;
}

#module-attorneys.profile .profile-details tr:hover td {
	background-color: transparent;
}

#module-attorneys.profile .profile-details td {
	padding-bottom: 7px;
}

#module-attorneys.profile .profile-details td.label {
	font-weight: bold;
	padding-right: 20px;
}

#module-attorneys.profile .profile-download-links table {
	width: 100%;
}

#module-attorneys.profile .profile-download-links td .link {
	display: inline-block;
	margin-right: 30px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 30px;
	color: #96653d;
	font-weight: 600;
	border-bottom: 1px solid transparent;
	font-size: 0.9rem;
	transition: none;
}

#module-attorneys.profile .profile-download-links td .link.vcard {
	background-image: url("/20150810114905/assets/images/vcard.svg");
	background-position: 0px 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

#module-attorneys.profile .profile-download-links td .link.vcard:hover {
	background-image: url("/20150810114905/assets/images/vcard-hover.svg");
	color: #726869;
}

#module-attorneys.profile .profile-download-links td .link.print {
	background-image: url("/20150810114905/assets/images/print.svg");
	background-position: 0px 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

#module-attorneys.profile .profile-download-links td .link.print:hover {
	background-image: url("/20150810114905/assets/images/print-hover.svg");
	color: #726869;
}

#module-attorneys.profile .profile-download-links td .link.linkedIn {
	background-image: url("/20150810114905/assets/images/linkedin.svg");
	background-position: 0px 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

#module-attorneys.profile .profile-download-links td .link.linkedIn:hover {
	background-image: url("/20150810114905/assets/images/linkedin-hover.svg");
	color: #726869;
}

@media (max-width: 1070px) {
	#module-attorneys.profile .profile-download-links td {
		white-space: nowrap;
	}

	#module-attorneys.profile .profile-download-links td .link {
		font-size: 0.8rem;
	}
}

@media (max-width: 767px) {
	#module-attorneys.profile .profile-left,
	#module-attorneys.profile .profile-right {
		float: none;
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.content-box-image {
		margin-bottom: 20px;
	}
}

@media (max-width: 479px) {
	#module-attorneys.profile .profile-details td {
		display: inline-block;
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}
}


/* Industry Groups  - list */
#module-industrygroups {
	margin-top: 0px;
	display: flex;
	flex-wrap: wrap;
}

#module-industrygroups .industry-group {
	border-bottom: 1px solid #efefef;
	padding-bottom: 1rem;
	padding-top: 1rem;
	width: 100%;
	margin: 0px;
}

#module-industrygroups .industry-group:first-child {
	padding-top: 0px;
}

/* Industry Groups  - details */
#module-industrygroups #group-sidebar,
#module-industrygroups #group-main {
	width: 100% !important;
	float: none;
}

#module-industrygroups #group-main {
	order: 0;
	margin-bottom: 20px;
}

#module-industrygroups #group-main p:first-child {
	margin-top: 0px;
}

#module-industrygroups #group-sidebar {
	order: 2;
}

#module-industrygroups #group-sidebar #group-attorneys {
	color: inherit;
	padding: 0px;
	border: 0px;
	margin-bottom: 20px;
}

#module-industrygroups #group-sidebar #group-attorneys h2 {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 5px solid #ddd;
	font-size: 1.25rem;
	line-height: 26px;
	margin-top: 0px;
	color: #726869;
	text-transform: none;
}

#module-industrygroups #group-sidebar #group-attorneys ul {
	list-style-type: disc;
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

#module-industrygroups #group-sidebar #group-attorneys ul li a {
	color: #96653d;
}

#module-industrygroups #group-sidebar #group-attorneys ul li a:hover {
	border-bottom-color: transparent;
	color: #25282a;
	text-decoration: none;
}
